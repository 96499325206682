
export default {
  name: 'Modal',
  components: {
    IconClose: () => import('@/assets/icons/close_icon.svg')
  },
  props: {
    modalOpen: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModalGlobal', true)
    }
  }
}
